// Header.jsx
import React, { useState } from 'react';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-blue-500 text-white p-4 fixed w-full top-0 z-10 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src="/images/logo.png" alt="Logo" className="h-8 w-8 mr-2" />
          <span className="font-bold text-xl">IT Research Labs</span>
        </div>
        <nav>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
          <ul className={`md:flex space-x-4 ${menuOpen ? 'block' : 'hidden'} md:block`}>
            <li><a href="#home" className="hover:text-gray-300 block md:inline-block">Home</a></li>
            <li><a href="#about" className="hover:text-gray-300 block md:inline-block">About</a></li>
            <li><a href="#services" className="hover:text-gray-300 block md:inline-block">Services</a></li>
            <li><a href="#blog" className="hover:text-gray-300 block md:inline-block">Blog</a></li>
            <li><a href="#career" className="hover:text-gray-300 block md:inline-block">Career</a></li>
            <li>
              <a
                href="#contact"
                className="bg-white text-blue-500 py-2 px-4 rounded hover:bg-blue-600 hover:text-white transition duration-300 block md:inline-block"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
