import React from 'react';

const services = [
  {
    title: 'Software Development',
    description: 'Innovative software development company delivering bespoke, cutting-edge solutions tailored to elevate your business efficiency and drive digital transformation.',
    gradient: 'from-blue-400 to-purple-600'
  },
  {
    title: 'Application Development',
    description: 'Leading app development company utilizing the latest technologies to create innovative, user-friendly applications that drive business growth and enhance user experiences.',
    gradient: 'from-green-400 to-blue-600'
  },
  {
    title: 'Website Development',
    description: 'Premier website development company leveraging the latest technologies to create dynamic, responsive, and visually stunning websites that elevate your online presence.',
    gradient: 'from-purple-400 to-pink-600'
  },
  {
    title: 'IT Consultancy',
    description: 'Providing strategic IT consultancy to optimize technology investments and achieve business goals with a clear roadmap and expert guidance.',
    gradient: 'from-yellow-400 to-orange-600'
  },
  {
    title: ' AI Project',
    description: 'Providing top-notch AI project development and services, tailored to boost your business efficiency and innovation.',
    gradient: 'from-teal-400 to-cyan-600'
  },
  {
    title: 'Digital Marketing',
    description: 'Offering exceptional digital marketing services to elevate your brand, increase engagement, and drive measurable results.',
    gradient: 'from-red-400 to-purple-600'
  }
];

const Services = () => {
  return (
    <section id="services" className="py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Our Services</h2>
          <p className="text-lg">Innovative IT solutions company specializing in cutting-edge software, application, and website development, delivering customized digital solutions to elevate your business.</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div 
              key={index} 
              className={`relative rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105 bg-gradient-to-r ${service.gradient}`}
            >
              <div className="p-6 h-full flex flex-col justify-end bg-black bg-opacity-50">
                <h3 className="text-2xl font-bold mb-2 text-white">{service.title}</h3>
                <p className="text-white">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
