// Become.jsx
import React from 'react';
import SocialMediaIcons from './SocialMediaIcons';

const Become = () => {
    return (
        <div className="bg-sky-400 text-white py-10 px-5">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
                <div>
                    <h2 className="text-lg font-semibold mb-4">Business Enquiry</h2>
                    <p><a href="mailto:info@itresearchlabs.com" className="hover:underline">info@itresearchlabs.com</a></p>
                    <p><a href="tel:+918814890630" className="hover:underline">+91-8814890630</a></p>
                    <p><a href="https://wa.me/8814890630" className="hover:underline">WhatsApp: +91-8814890630</a></p>
                    <SocialMediaIcons />
                </div>
                <div>
                    <h2 className="text-lg font-semibold mb-4">Human Resources</h2>
                    <p><a href="mailto:info@itresearchlabs.com" className="hover:underline">info@itresearchlabs.com</a></p>
                    <p><a href="tel:+918814890630" className="hover:underline">+91-8814890630</a></p>
                    <p><a href="https://wa.me/918814890630" className="hover:underline">WhatsApp: +91-8814890630</a></p>
                    <SocialMediaIcons />
                </div>
                <div>
                    <h2 className="text-lg font-semibold mb-4">Our Services</h2>
                    <ul>
                        <li className="mb-2">Web Development</li>
                        <li className="mb-2">Software Development</li>
                        <li className="mb-2"> App Development</li>
                        <li className="mb-2">Digital Marketing</li>
                        <li className="mb-2">AI Project</li>
                    </ul>
                </div>
                <div>
                    <h2 className="text-lg font-semibold mb-4">Hire Professional Developers</h2>
                    <ul>
                        <li className="mb-2">Hire Android Developers</li>
                        <li className="mb-2">Hire MERN Stack Developers</li>
                        <li className="mb-2">Hire AI Project Developers</li>
                        <li className="mb-2">Hire Software Developers</li>
                        <li className="mb-2">Hire iOS Developers</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Become;
