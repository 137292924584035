import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Our Team</h2>
          <p className="text-lg">
            Our team at <span className="font-bold"> IT Research Labs</span> consists of experienced developers, creative designers, and strategic marketers. Dedicated to excellence and innovation, we collaborate to deliver tailored solutions, staying ahead of industry trends. Our expertise and commitment ensure the best outcomes, turning ideas into reality.
          </p>
        </div>

        <div className="text-center my-12">
          <h2 className="text-4xl font-bold mb-4">What Do We Do</h2>
          <img src="/images/work.gif" alt="Infographic" className="mx-auto" />
        </div>

        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-12 rounded-lg">
          <div className="text-center mb-8">
            <h2 className="text-4xl font-bold mb-4">Our Completed Projects</h2>
            <p className="text-lg">Completed 225+ successful projects collectively</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-4">
            {[
              {
                title: 'Software Development',
                description: 'Successfully completed 80+ software projects, creating bespoke solutions tailored to enhance business efficiency and drive digital transformation.'
              },
              {
                title: 'Application Development',
                description: 'Delivered over 60+ successful application projects, utilizing the latest technologies to ensure seamless user experiences and high performance on iOS and Android platforms.'
              },
              {
                title: 'Web Development',
                description: 'Achieved over 85+ successful web projects, crafting responsive and visually stunning websites using modern technologies to boost your online presence.'
              },
              {
                title: 'IT Consultancy',
                description: 'Provided strategic IT consultancy to numerous clients, helping them optimize their technology investments and achieve business goals.'
              },
              {
                title: 'Digital Marketing',
                description: 'Our expertise spans 110+ successful projects, delivering outstanding results and driving digital growth for our clients.'
              },
              {
                title: 'AI Projects',
                description: 'Successfully completed 25+ AI projects, seamlessly integrating cutting-edge solutions with websites and apps for enhanced user experiences.'
              }
            ].map((card, index) => (
              <div key={index} className="bg-white text-black p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                <h3 className="text-2xl font-bold mb-2">{card.title}</h3>
                <p>{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
