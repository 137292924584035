// SocialMediaIcons.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram, faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
    return (
        <div className="fixed top-20 right-5 flex flex-col space-y-4">
            <a
                href="https://www.linkedin.com/in/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-700 text-white p-2 rounded-full shadow-lg hover:bg-blue-800 transition-colors duration-300"
            >
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
            <a
                href="https://www.facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 text-white p-2 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
            >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
            <a
                href="https://www.instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-pink-500 text-white p-2 rounded-full shadow-lg hover:bg-pink-600 transition-colors duration-300"
            >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a
                href="https://twitter.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-400 text-white p-2 rounded-full shadow-lg hover:bg-blue-500 transition-colors duration-300"
            >
                <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
            <a
                href="https://t.me/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300"
            >
                <FontAwesomeIcon icon={faTelegram} size="lg" />
            </a>
        </div>
    );
};

export default SocialMediaIcons;
