import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Career from './components/Career'; // Import Career component
import Become from './components/Become';
import WhatsAppIcon from './components/WhatsAppIcon';
import SocialMediaIcons from './components/SocialMediaIcons';
const App = () => {
  return (
    <div>
      <Header />
      <main className="pt-16">
        <Home />
        <About />
        <Services />
        <Contact />
        <Blog />
        <Career /> {/* Career section */}
        <Become />
        <WhatsAppIcon /> {/* Become section */}
        <SocialMediaIcons />
      </main>
      <Footer />
    </div>
  );
};

export default App;
