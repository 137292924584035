import React from 'react';

const Become = () => {
  return (
    <section id="home" className="relative min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Background Gradient */}
      <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>

      {/* Transparent Content Overlay */}
      <div className="relative z-10 text-center text-white bg-black bg-opacity-50 p-8 rounded-md mt-16">
        <h1 className="text-4xl font-bold">Your business IT service consultation is free</h1>
        <p className="mt-4 text-lg">Join us and grow your business.</p>
        <button className="mt-6 px-6 py-2 border border-white rounded-md hover:bg-white hover:text-black transition duration-300">
  <a href="#contact">Get Started</a>
</button>

      </div>

      {/* Spacer */}
      <div className="mt-2
      "></div>

      {/* Transparent Three Cards */}
      <div className="relative z-10 mt-8 grid grid-cols-1 md:grid-cols-3 gap-2 p-8 w-full">
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">Web Development</h2>
          <p className="mt-2">Creating responsive and dynamic websites tailored to your business needs.</p>
        </div>
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">AI Projects</h2>
          <p className="mt-2">Advanced AI Integration Services for your projects.</p>
        </div>
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">Software Development</h2>
          <p className="mt-2">Custom software solutions to streamline your operations.</p>
        </div>
      </div>

      {/* Spacer */}
      <div className="mt-2"></div>

      {/* Additional Three Cards */}
      <div className="relative z-10 grid grid-cols-1 md:grid-cols-3 gap-2 p-8 w-full">
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">Digital Marketing</h2>
          <p className="mt-2">Boost your online visibility and engagement effectively.</p>
        </div>
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">App Development</h2>
          <p className="mt-2">Crafting innovative and high-performance mobile and web applications for your business success.</p>
        </div>
        <div className="bg-white bg-opacity-50 p-4 rounded-md">
          <h2 className="text-2xl font-bold">Data Analytics</h2>
          <p className="mt-2">Gain insights with comprehensive data analytics services.</p>
        </div>
      </div>
    </section>
  );
};

export default Become;
