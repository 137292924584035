// WhatsAppIcon.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppIcon = () => {
    return (
        <a
            href="https://wa.me/8814890630"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-10 right-5 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
        >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
    );
};

export default WhatsAppIcon;
