import React from 'react';

const blogPosts = [
  {
    title: 'Website Development',
    description: 'Discover the latest trends and technologies in website development, including frameworks, design principles, and optimization techniques.',
    link: '#'
  },
  {
    title: 'App Development',
    description: 'Learn about the best practices in app development for both Android and iOS platforms, including UX/UI design, performance, and security.',
    link: '#'
  },
  {
    title: 'Software Development',
    description: 'Explore the world of software development with insights on methodologies, programming languages, tools, and the latest innovations in the industry.',
    link: '#'
  },
  {
    title: 'Digital Marketing',
    description: 'Stay ahead in digital marketing with tips on SEO, social media strategies, content marketing, and data analytics to drive business growth.',
    link: '#'
  },
  {
    title: 'IT Services',
    description: 'Understand the various IT services offered by companies, including cloud computing, network management, cybersecurity, and technical support.',
    link: '#'
  },
  {
    title: 'Business Consulting',
    description: 'Get expert advice on business consulting for IT companies, covering areas like strategy development, process improvement, and change management.',
    link: '#'
  },
];

const BlogSection = () => {
  return (
    <section id="blog" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Latest Articles</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <div key={index} className="bg-white rounded shadow-lg p-6 transition duration-300 hover:shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">{post.title}</h3>
              <p className="text-gray-700 mb-4">{post.description}</p>
              <a href={post.link} className="text-blue-500 hover:underline">Read More</a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
