import React from 'react';
import '../styles/CareerComponent.css';

const Career = () => {
  return (
    <section id="career" className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-500 text-white p-4">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center">Start Your Career at IT Research Labs</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          {[
            'MERN Stack Developer',
            'React Js Developer',
            'HR IT EXECUTIVE',
            'Wordpress Developer',
            'Business Executive',
            'AI Developer',
            'Android Developers',
            'Node Js Developers',
            'PHP Developers',
            'SEO/ASO Executive',
            'Ecommerce Developers',
            'Shopify Developers',
          ].map((role) => (
            <div key={role} className="p-4 bg-purple-700 rounded shadow-lg hover:shadow-xl transition duration-300 text-center">
              {role}
            </div>
          ))}
        </div>
        <div className="opacity-70 p-4 bg-purple-800 rounded shadow-lg text-center">
          Send Your Resume: <a href="mailto:info@itresearchlabs.com" className="underline hover:text-indigo-300">info@itresearchlabs.com</a>
        </div>
      </div>
    </section>
  );
};

export default Career;
